<template>
  <v-card height="100%" color="backgroundColor">
    <h1 class="primary--text ma-4">Statistiques générales</h1>
    <v-row class="ma-0 pa-0">
      <v-col cols="12" offset-md="1" md="10" align-center>
        <StatistiqueMain
          :datas="dataGenerales"
          @changedatemin="setDatemin"
          @changedatemax="setDatemax"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import StatistiqueMain from "./../components/StatistiqueVueComponent/StatistiqueMain.vue";
import StatistiquesService from "../services/statistiquesService";
export default {
  components: {
    StatistiqueMain,
  },
  data: () => ({
    colors: [
      "#034155",
      "#d5dde1",
      "#fb4d61",
      "#f7cfd3",
      "#67afb1",
      "#845f92",
      "#DC0000",
      "#ABCC01",
      "#019934",
      "#0099BB",
      "#343399",
      "#883388",
    ],
    mois: [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ],
    retourBack: {
      nbTotalUtilisateurs: null,
      nbTotalUtilisateursActif: null,
      nbTotalUtilisateurProf: null,
      nbTotalUtilisateurProfActif: null,
      nbTotalUtilisateurEleve: null,
      nbTotalUtilisateurEleveActif: null,
      nbTotalUtilisateursDate: null,
      nbTotalUtilisateursActifDate: null,
      nbTotalUtilisateurProfDate: null,
      nbTotalUtilisateurEleveDate: null,
      nbTotalUtilisateurEleveActifDate: null,
      nbTotalUtilisateurElevePrimaire: null,
      nbTotalUtilisateurEleveCollege: null,
      nbTotalUtilisateurEleveLycee: null,
      listStatEleveJour: [],
    },
    loaded: false,
    options: { startDate: "", endDate: "" },
  }),
  mounted() {
    this.initialize();
  },
  watch: {
    "options.startDate"() {
      this.initialize();
    },
    "options.endDate"() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      this.loading = true;
      const { startDate, endDate } = this.options;
      StatistiquesService.getStatsGenerales(startDate, endDate).then(
        (element) => {
          this.retourBack = element.data;
          this.retourBack.loaded = true;
          this.retourBack.niveaux = [
            { nom: "Primaire", nbrtoteleveniveau: 2, nbrtotaleleveactifniv: 1 },
            { nom: "Collège", nbrtoteleveniveau: 2, nbrtotaleleveactifniv: 1 },
            {
              nom: "Lycée générale",
              nbrtoteleveniveau: 2,
              nbrtotaleleveactifniv: 1,
            },
            {
              nom: "Lycée technologique",
              nbrtoteleveniveau: 2,
              nbrtotaleleveactifniv: 1,
            },
            {
              nom: "Supérieur",
              nbrtoteleveniveau: 2,
              nbrtotaleleveactifniv: 1,
            },
          ];
        }
      );
    },
    setDatemin(payload) {
      this.options.startDate =
        payload.message.substring(8, 10) +
        "/" +
        payload.message.substring(5, 7) +
        "/" +
        payload.message.substring(0, 4);
    },
    setDatemax(payload) {
      this.options.endDate =
        payload.message.substring(8, 10) +
        "/" +
        payload.message.substring(5, 7) +
        "/" +
        payload.message.substring(0, 4);
    },
  },
  computed: {
    dataGenerales() {
      return [
        {
          name: "Utilisateurs activés",
          type: "donuts",
          loaded: this.retourBack.loaded,
          chartData: this.ElevesActifsDatas,
        },

        // {
        //   name: "Elèves activés par niveau",
        //   type: "bar",
        //   loaded: this.retourBack.loaded,
        //   chartData: this.ElevesActifsNivDatas,
        // },

        {
          name: "Activité par jour",
          type: "line",
          loaded: this.retourBack.loaded,
          chartData: this.ElevesActifsDateDatas,
        },
      ];
    },
    ElevesActifsNivDatas() {
      let nbrelevesinactif = [];
      let nbreleveactif = [];
      let chartData = {
        labels: [],
        datasets: [],
      };
      if (this.retourBack.niveaux) {
        this.retourBack.niveaux.forEach((niveau) => {
          chartData.labels.push(niveau.nom);
          nbrelevesinactif.push(
            niveau.nbrtoteleveniveau - niveau.nbrtotaleleveactifniv
          );
          nbreleveactif.push(niveau.nbrtotaleleveactifniv);
        });
        chartData.datasets = [
          {
            type: "bar",
            label: "Elèves activés",
            backgroundColor: this.colors[0],
            data: nbreleveactif,
            datalabels: {
              color: this.colors[1],
            },
          },
          {
            type: "bar",
            label: "Elèves désactivés",
            backgroundColor: this.colors[1],
            data: nbrelevesinactif,
            datalabels: {
              color: this.colors[0],
            },
          },
        ];
      }
      return chartData;
    },
    ElevesActifsDatas() {
      let totalEleve = 0;
      let totalProf = 0;
      let eleveActif = 0;
      let profActif = 0;

      this.retourBack.listStatEleveJour.forEach((element) => {
        totalEleve += element.nbEleve;
        totalProf += element.nbProf;
        eleveActif += element.nbEleveActif;
        profActif += element.nbProfActif;
      });

      let chartData = {
        labels: [
          "Elèves activés",
          "Professeur activés",
          "Elèves désactivés",
          "Professeur désactivés",
        ],
        datasets: [
          {
            backgroundColor: [
              this.colors[0],
              this.colors[2],
              this.colors[1],
              this.colors[3],
            ],
            data: [
              // actifs
              eleveActif,
              profActif,
              totalEleve - eleveActif,
              // inactifs
              totalProf - profActif,
            ],
            datalabels: {
              color: [this.colors[1], this.colors[0]],
            },
          },
        ],
      };

      return chartData;
    },
    ElevesActifsDateDatas() {
      let nbreleves = [];
      let nbrprof = [];
      let nbreleveactif = [];
      let nbrprofactif = [];
      let chartData = {
        labels: [],
        datasets: [],
        maxValue: 0,
      };
      this.retourBack.listStatEleveJour.forEach((jour) => {
        let date =
          jour.date.substring(8, 10) +
          " " +
          this.mois[+jour.date.substring(5, 7) - 1] +
          " " +
          jour.date.substring(0, 4);

        chartData.labels.push(date);
        if (chartData.maxValue < jour.nbEleve) {
          chartData.maxValue = jour.nbEleve;
        }
        nbreleves.push(jour.nbEleve);
        if (chartData.maxValue < jour.nbEleveActif) {
          chartData.maxValue = jour.nbEleveActif;
        }
        nbreleveactif.push(jour.nbEleveActif);
        if (chartData.maxValue < jour.nbProf) {
          chartData.maxValue = jour.nbProf;
        }
        nbrprof.push(jour.nbProf);
        if (chartData.maxValue < jour.nbProfActif) {
          chartData.maxValue = jour.nbProfActif;
        }
        nbrprofactif.push(jour.nbProfActif);
      });
      chartData.datasets = [
        {
          type: "line",
          label: "Elèves créés",
          borderColor: this.colors[4],
          backgroundColor: "transparent",
          borderWidth: 1,
          data: nbreleves,
          datalabels: {
            align: "end",
            color: "transparent",
          },
        },
        {
          type: "line",
          label: "Professeurs créés",
          borderColor: this.colors[5],
          backgroundColor: "transparent",
          borderWidth: 1,
          data: nbrprof,
          datalabels: {
            align: "end",
            color: "transparent",
          },
        },
        {
          type: "line",
          label: "Elèves activés",
          borderColor: this.colors[0],
          backgroundColor: "transparent",
          borderWidth: 1,
          data: nbreleveactif,
          datalabels: {
            align: "end",
            color: "transparent",
          },
        },
        {
          type: "line",
          label: "Professeurs activés",
          borderColor: this.colors[2],
          backgroundColor: "transparent",
          borderWidth: 1,
          data: nbrprofactif,
          datalabels: {
            align: "end",
            color: "transparent",
          },
        },
        
      ];
      return chartData;
    },
  },
};
</script>

<style>
</style>
